import { createApi } from "@reduxjs/toolkit/query/react";
import { fetcher } from "./fetcher";
import { transformAdequacyMetricApiResponse } from "./utils";
import { isNewRowId } from "../../hooks/useEditableGrid.utils";
import {
  invalidateCacheOnNewNetworkState,
} from "../helpers";

export const j2Api = createApi({
  reducerPath: "j2Api",
  keepUnusedDataFor: 7200,
  baseQuery: fetcher,
  endpoints: (build) => ({
    //
    // Standards endpoints
    //
    getCustomStandards: build.query({
      query: ({ archived } = {}) => ({
        method: "GET",
        url: `/adequacy/standards/${archived ? "archived/" : ""}`,
      }),
      providesTags: (_result, _error, { archived } = {}) => [
        { type: archived ? "ArchivedStandards" : "Standards", id: "ALL" },
      ],
    }),
    postCustomStandards: build.mutation({
      query: ({ data }) => {
        return {
          method: "POST",
          url: `/adequacy/standards/`,
          body: data,
        };
      },
      invalidatesTags: (_result, error, { skipInvalidateAll }) => {
        return error
          ? []
          : [skipInvalidateAll ? {} : { type: "Standards", id: "ALL" }];
      },
    }),
    postPublishCustomStandards: build.mutation({
      query: ({ standard }) => {
        return {
          method: "POST",
          url: `/adequacy/standards/${standard.id}/publish/`,
        };
      },
      invalidatesTags: (_result, error, _arg) => {
        return error ? [] : [{ type: "Standards", id: "ALL" }];
      },
    }),
    postCloneCustomStandards: build.mutation({
      query: ({ standard, data }) => {
        return {
          method: "POST",
          url: `/adequacy/standards/${standard.id}/clone/`,
          body: data,
        };
      },
      invalidatesTags: (_result, error, _arg) => {
        return error ? [] : [{ type: "Standards", id: "ALL" }];
      },
    }),
    putCustomStandards: build.mutation({
      query: ({ standard, data }) => {
        return {
          method: "PUT",
          url: `/adequacy/standards/${standard.id}/`,
          body: {
            ...standard,
            ...data,
          },
        };
      },
      invalidatesTags: (
        _result,
        error,
        { standard, data, skipInvalidateAll }
      ) => {
        if (error) return [];
        const tags = [
          { type: "StandardDesignationTypes", id: standard.id },
          { type: "StandardDesignations", id: standard.id },
          { type: "StandardRequirements", id: standard.id },
        ];
        if (!data.draft) tags.push({ type: "ReleasedStandards", id: "ALL" });
        if (!skipInvalidateAll) tags.push({ type: "Standards", id: "ALL" });
        return tags;
      },
    }),
    deleteCustomStandards: build.mutation({
      query: ({ standard }) => {
        return {
          method: "DELETE",
          url: `/adequacy/standards/${standard.id}/`,
        };
      },
      invalidatesTags: (_result, error, _arg) => {
        return error ? [] : [{ type: "Standards", id: "ALL" }];
      },
    }),
    archiveCustomStandards: build.mutation({
      query: ({ standard }) => {
        return {
          method: "DELETE",
          url: `/adequacy/standards/${standard.id}/`,
        };
      },
      invalidatesTags: (_result, error, _arg) => {
        return error
          ? []
          : [
              { type: "Standards", id: "ALL" },
              { type: "ArchivedStandards", id: "ALL" },
              { type: "ReleasedStandards", id: "ALL" },
            ];
      },
    }),
    unarchiveCustomStandards: build.mutation({
      query: ({ standard }) => {
        return {
          method: "PUT",
          url: `/adequacy/standards/${standard.id}/unarchive/`,
        };
      },
      invalidatesTags: (_result, error, _arg) => {
        return error
          ? []
          : [
              { type: "Standards", id: "ALL" },
              { type: "ArchivedStandards", id: "ALL" },
              { type: "ReleasedStandards", id: "ALL" },
            ];
      },
    }),

    //
    // Specialties endpoints
    //
    getCustomSpecialties: build.query({
      query: (standard_id) => ({
        method: "GET",
        url: `/adequacy/standards/${standard_id}/specialties/`,
      }),
      providesTags: () => [{ type: "StandardSpecialties", id: "ALL" }],
    }),

    postCustomSpecialties: build.mutation({
      query: ({ standard_id, data }) => {
        return {
          method: "POST",
          url: `/adequacy/standards/${standard_id}/specialties/`,
          body: data.map((s) => ({
            ...s,
            id: isNewRowId(s.id) ? null : s.id, // new specialties have id starting with "!"
          })),
        };
      },
      invalidatesTags: (_result, error, arg) => {
        return error
          ? []
          : [
              { type: "StandardSpecialties", id: "ALL" },
              { type: "StandardRequirements", id: arg.standard_id },
            ];
      },
    }),
    //
    // Member universes endpoints
    //
    getMemberUniverses: build.query({
      query: () => ({
        method: "GET",
        url: `/adequacy/memberuniverses/`,
      }),
      providesTags: () => [{ type: "Members", id: "ALL" }],
    }),

    postValidateMemberFile: build.mutation({
      query: (data) => {
        const body = new FormData();
        body.append("file", data.file);
        return {
          method: "POST",
          url: `/adequacy/memberuniverses/validate/`,
          body,
        };
      },
    }),

    //
    // Standard member universes endpoints
    //
    getStandardMemberUniverses: build.query({
      query: (standard_id) => ({
        method: "GET",
        url: `/adequacy/standards/${standard_id}/member-universes/`,
      }),

      providesTags: (_result, _err, standard_id) => {
        if (!standard_id) return [];
        return [
          { type: "StandardMemberUniverses", standard_id: "ALL" },
          { type: "StandardMemberUniverses", standard_id: standard_id },
        ];
      },
    }),

    postStandardMemberUniverses: build.mutation({
      query: ({ standard_id, data }) => {
        const body = new FormData();
        body.append("file", data.file);
        return {
          method: "POST",
          url: `/adequacy/standards/${standard_id}/member-universes/`,
          body,
        };
      },
      invalidatesTags: (_result, error, { standard_id }) => {
        return error
          ? []
          : [
              { type: "StandardMemberUniverses", standard_id: standard_id },
              { type: "Standards", id: "ALL" },
            ];
      },
    }),

    putStandardMemberUniverses: build.mutation({
      query: ({ standard_id, data }) => {
        const body = new FormData();
        body.append("file", data.file);
        return {
          method: "PUT",
          url: `/adequacy/standards/${standard_id}/member-universes/${data.memberUniverseId}/`,
          body,
        };
      },
      invalidatesTags: (_result, error, { standard_id }) => {
        return error
          ? []
          : [{ type: "StandardMemberUniverses", standard_id: standard_id }];
      },
    }),

    //
    // Adequacy metrics endpoint
    //
    getAdequacyMetrics: build.query({
      query: () => ({
        method: "GET",
        url: `/adequacy/metrics/`,
      }),
      transformResponse: (response, _meta, _arg) =>
        transformAdequacyMetricApiResponse(response),

      providesTags: () => [{ type: "Metrics", id: "ALL" }],
    }),
    //
    // Standard designation types endpoints
    //
    getCustomStandardDesignationTypes: build.query({
      query: (standard_id = 0) => ({
        method: "GET",
        url: `/adequacy/standards/${standard_id}/designation-types/`,
      }),

      providesTags: (_result, _err, standard_id) => {
        if (!standard_id) return [];
        return [
          { type: "StandardDesignationTypes", standard_id: "ALL" },
          { type: "StandardDesignationTypes", standard_id: standard_id },
        ];
      },
    }),

    getCustomStandardDesignationType: build.query({
      query: ({ standard_id, designation_type_id }) => ({
        method: "GET",
        url: `/adequacy/standards/${standard_id}/designation-types/${designation_type_id}/`,
      }),

      providesTags: (_result, _err, { standard_id, designation_type_id }) => {
        return [
          {
            type: "SingleStandardDesignationType",
            standard_id,
            designation_type_id,
          },
        ];
      },
    }),

    postCustomStandardDesignationTypes: build.mutation({
      query: ({ standard_id, data }) => ({
        method: "POST",
        url: `/adequacy/standards/${standard_id}/designation-types/`,
        body: {
          ...data,
          designations: data.designations.map((d) => ({
            ...d,
            id: isNewRowId(d.id) ? null : d.id, // new designations have id starting with "!"
          })),
        },
      }),

      invalidatesTags: (_result, error, { standard_id, skipInvalidateAll }) => {
        return error
          ? []
          : [
              { type: "StandardDesignationTypes", standard_id: standard_id },
              skipInvalidateAll ? {} : { type: "Standards", id: "ALL" },
            ];
      },
    }),

    putCustomStandardDesignationType: build.mutation({
      query: ({ standard_id, data }) => ({
        method: "PUT",
        url: `/adequacy/standards/${standard_id}/designation-types/${data.designation_type_id}/`,
        body: {
          ...data,
          designations: data.designations.map((d) => ({
            ...d,
            id: isNewRowId(d.id) ? null : d.id, // new designations have id starting with "!"
          })),
        },
      }),

      invalidatesTags: (_result, error, arg) => {
        return error
          ? []
          : [
              {
                type: "SingleStandardDesignationType",
                standard_id: arg.standard_id,
                designation_type_id: arg.data.designation_type_id,
              },
              { type: "Standards", id: "ALL" },
            ];
      },
    }),
    //
    // Standard designations endpoint
    //
    getCustomStandardDesignations: build.query({
      query: (standard_id) => ({
        method: "GET",
        url: `/adequacy/standards/${standard_id}/designations/`,
      }),

      providesTags: (_result, _err, standard_id) => [
        { type: "StandardDesignations", id: "ALL" },
        { type: "StandardDesignations", id: standard_id },
      ],
    }),

    //
    // Standard requirements endpoints
    //
    getCustomStandardRequirements: build.query({
      query: (standard_id) => ({
        method: "GET",
        url: `/adequacy/standards/${standard_id}/requirements/`,
      }),

      providesTags: (_result, _err, standard_id) => {
        return [
          { type: "StandardRequirements", id: "ALL" },
          { type: "StandardRequirements", id: standard_id },
        ];
      },
    }),

    postCustomStandardRequirements: build.mutation({
      query: ({ standard_id, data }) => {
        return {
          method: "POST",
          url: `/adequacy/standards/${standard_id}/requirements/bulk/`,
          body: data,
        };
      },
      invalidatesTags: (_result, error, arg) => {
        return error
          ? []
          : [
              { type: "StandardSpecialties", id: "ALL" },
              { type: "StandardRequirements", id: arg.standard_id },
            ];
      },
    }),

    //
    // Standards for network form endpoints
    //
    getNetworkFormStandards: build.query({
      query: () => ({
        method: "GET",
        url: `/adequacy/network-self-serve-form/standards/`,
      }),

      providesTags: (_result, _err, _standard_id) => {
        return [{ type: "ReleasedStandards", id: "ALL" }];
      },
    }),
    //
    // Network update tasks
    //
    getNetworkUpdateTasks: build.query({
      query: () => ({
        method: "GET",
        url: `/adequacy/network-update-tasks/`,
      }),
    }),
    postFileUpload: build.mutation({
      query: (data) => {
        const body = new FormData();
        body.append("client_network", data.file);
        body.append("client_name", data.client_name);
        body.append("taxonomy_id", data.lob);
        return {
          method: "POST",
          url: `/adequacy/network-self-serve-form/file/upload`,
          body,
        };
      },
      transformResponse: (response) => {
        return {
          Columns: response.columns,
          ExtraColumns: response.extra_columns,
          InputFileName: response.input_file_name,
          ValidFileName: response.valid_file_name,
          Fatals: response.fatals,
          Errors: response.errors,
          Warnings: response.warnings,
          FatalRowCount: response.fatal_row_count,
          ErrorRowCount: response.error_row_count,
          WarningRowCount: response.warning_row_count,
          message: response.message,
        };
      },
    }),
    postFileProcess: build.mutation({
      query: (data) => {
        const {
          user,
          networkId,
          networkName,
          file,
          lob,
          counties,
          client_name,
          updateNetworkId
        } = data;
        let body = {
          file_name: file?.name ?? "Empty_Network.csv",
          fips: counties.map((c) => c.fips),
          network: networkId === "" ? null : networkId,
          user_id: user.email,
          user_name: user.nickname,
          update_network_id: updateNetworkId,
        };
        body.client_name = client_name;
        body.taxonomy_id = lob;
        if (networkName) body.network_name = networkName;
        return {
          method: "POST",
          url: `/adequacy/network-self-serve-form/file/process`,
          body,
        };
      },
    }),
    //
    // Disruption networks endpoints
    //
    postDisruptionNetworks: build.mutation({
      query: (payload) => {
        const { name, networkType, file, user } = payload;
        const body = new FormData();
        body.append("name", name);
        body.append("type", networkType);
        body.append("file", file);
        body.append("created_by_email", user.email);
        return {
          method: "POST",
          url: `/disruption/networks/`,
          body,
        };
      },
      invalidatesTags: (_result, error, _payload) => {
        return error ? [] : [{ type: "DisruptionNetworks", id: "ALL" }];
      },
    }),
    getDisruptionNetworks: build.query({
      query: () => ({
        method: "GET",
        url: "/disruption/networks/",
      }),
      providesTags: (_result, _err, _standard_id) => {
        return [{ type: "DisruptionNetworks", id: "ALL" }];
      },
    }),

    //
    // Disruption reports endpoints
    //

    getDisruptionReports: build.query({
      query: () => ({
        method: "GET",
        url: "/disruption/reports/",
      }),
      providesTags: (_result, _err, _standard_id) => {
        return [{ type: "DisruptionReports", id: "ALL" }];
      },
    }),

    postDisruptionReports: build.mutation({
      query: (payload) => {
        return {
          method: "POST",
          url: `/disruption/reports/`,
          body: payload,
        };
      },
      invalidatesTags: (_result, error, _payload) => {
        return error ? [] : [{ type: "DisruptionReports", id: "ALL" }];
      },
    }),

    postDisruptionReportsGenerate: build.mutation({
      query: (payload) => {
        return {
          method: "POST",
          url: `/disruption/reports/generate/`,
          body: payload,
        };
      },
      invalidatesTags: (_result, error, _payload) => {
        return error ? [] : [{ type: "DisruptionReports", id: "ALL" }];
      },
    }),

    getMatchGroups: build.query({
      query: () => ({
        method: "GET",
        url: "/disruption/match-groups/",
      }),
      providesTags: (_result, _err, _standard_id) => {
        return [{ type: "MatchGroups", id: "ALL" }];
      },
    }),

    getDisruptionReportsResults: build.query({
      query: (report_id) => ({
        method: "GET",
        url: `/disruption/reports/${report_id}/results/`,
      }),
      providesTags: (_result, _err, report_id) => [
        { type: "DisruptionReportsResults", id: report_id }
      ],
      keepUnusedDataFor: 24 * 60 * 60, // 1 day
    }),

    //
    // Score simulation endpoints
    //

    postNetworkChanges: build.mutation({
      query: (data) => {
        const {
          networkId,
          toAdd,
          toRemove,
          user
        } = data;
        console.log("data", data);
        let body = {
          network_id: networkId,
          to_add: toAdd,
          to_remove: toRemove,
          user_id: user.email,
        };
        return {
          method: "POST",
          url: `/adequacy/score-simulation`,
          body,
        };
      },
      onQueryStarted: invalidateCacheOnNewNetworkState,
      invalidatesTags: (_result, error, arg) => {
            return error
              ? []
              : [
                  { type: "Scores", id: `${arg.networkId}-${arg.specialtyId}` },
                  { type: "Scores", id: "COUNTY-ALL" },
                ];
          },
    }),
  }),
});

export const {
  useGetCustomStandardsQuery,
  usePostCustomStandardsMutation,
  usePostPublishCustomStandardsMutation,
  usePostCloneCustomStandardsMutation,
  usePutCustomStandardsMutation,
  useDeleteCustomStandardsMutation,
  useArchiveCustomStandardsMutation,
  useUnarchiveCustomStandardsMutation,
  useGetCustomSpecialtiesQuery,
  usePostCustomSpecialtiesMutation,
  useGetMemberUniversesQuery,
  usePostValidateMemberFileMutation,
  useGetStandardMemberUniversesQuery,
  usePostStandardMemberUniversesMutation,
  usePutStandardMemberUniversesMutation,
  useGetAdequacyMetricsQuery,
  useGetCustomStandardDesignationTypesQuery,
  useGetCustomStandardDesignationTypeQuery,
  usePostCustomStandardDesignationTypesMutation,
  usePutCustomStandardDesignationTypeMutation,
  useGetCustomStandardDesignationsQuery,
  useGetCustomStandardRequirementsQuery,
  usePostCustomStandardRequirementsMutation,
  useGetNetworkFormStandardsQuery,
  useGetNetworkUpdateTasksQuery,
  usePostFileUploadMutation,
  usePostFileProcessMutation,
  usePostDisruptionNetworksMutation,
  useGetDisruptionNetworksQuery,
  useGetDisruptionReportsQuery,
  useGetDisruptionReportsResultsQuery,
  usePostDisruptionReportsMutation,
  usePostDisruptionReportsGenerateMutation,
  useGetMatchGroupsQuery,
  usePostNetworkChangesMutation,
} = j2Api;
