import { networksApi } from '../networksApi';
import { j2Api } from './j2Api';

/**
 * Middleware that listens for successful j2Api mutations and automatically
 * invalidates the corresponding networksApi cache.
 * 
 * This ensures that components using networksApi will refetch data
 * after j2Api has made changes to the backend data.
 */
export const crossApiCacheMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  
  // Check if this is a fulfilled j2Api postNetworkChanges action
  if (j2Api.endpoints.postNetworkChanges.matchFulfilled(action)) {
    const { networkId, specialtyId } = action.meta.arg.originalArgs;
    console.log('Cross-API cache invalidation middleware: j2Api postNetworkChanges succeeded, invalidating networksApi cache', {
      networkId,
      specialtyId
    });
    
    // Invalidate networksApi cache with specific tags
    store.dispatch(networksApi.util.invalidateTags([
      // Invalidate the network itself
      { type: 'Network', id: networkId },
      // Invalidate scores for this network-specialty combination
      { type: 'Scores', id: `${networkId}-${specialtyId}` },
      // Invalidate county scores
      { type: 'Scores', id: 'COUNTY-ALL' },
      // Invalidate any staged changes
      { type: 'Stage', id: networkId }
    ]));
  }
  
  return result;
};
