import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import RotateLeft from "@mui/icons-material/RotateLeft";
import { useFlags } from "launchdarkly-react-client-sdk";

import {
  selectStagedChanges,
  setToAdd,
  setToRemove,
} from "../../features/stagedChanges";
import { usePostNetworkChangesMutation as usePostNetworkChangesMutationLegacy } from "../../api/networksApi";
import { usePostNetworkChangesMutation as usePostNetworkChangesMutationNew } from "../../api/j2/j2Api";
import { selectSelectedNetworkId } from "../../features/selectedNetwork";
import { selectCurrentClient } from "../../features/currentClient";
import { selectSpecialtyFilterVal } from "../../features/providers";
import { Alert, Snackbar } from "@mui/material";
import { StagedChangesModal } from "../StagedChanges/StagedChangesModal";
import { useNetworkContextAmplitudeEvent } from "../../hooks/useNetworkContextAmplitudeEvent";

// List of clients that should always use legacy scoring
const LEGACY_SCORING_CLIENTS = ["oscar"];

/**
 * Checks if a client should use legacy scoring
 * @param {string} clientName - The name of the client/tenant
 * @returns {boolean} - True if the client should use legacy scoring
 */
const shouldUseLegacyScoring = (clientName) => {
  return LEGACY_SCORING_CLIENTS.includes(clientName?.toLowerCase());
};

function ProviderCart() {
  const [showItems, setShowItems] = useState(false);
  const dispatch = useDispatch();
  const clearStage = useCallback(() => {
    dispatch(setToAdd());
    dispatch(setToRemove());
  }, [dispatch]);

  const handleOpen = (val) => setShowItems(val);
  const handleClose = () => setShowItems(false);

  const [openSnack, setOpenSnack] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleOpenSnack = (error) => {
    console.error("Error applying changes:", error);
    setErrorMessage(error?.data?.message || "Your changes have not been saved. Please try again with a smaller number of specialties affected.");
    setOpenSnack(true);
  };

  const handleCloseSnack = (event, reason) => {
    setOpenSnack(false);
  };

  const networkId = useSelector(selectSelectedNetworkId);
  const specialtyId = useSelector(selectSpecialtyFilterVal);
  const { toAdd, toRemove } = useSelector(selectStagedChanges);
  const currentClient = useSelector(selectCurrentClient);
  const { user } = useAuth0();
  const useScoreSimulationApi = useFlags()["j2nm-2952-scores-deployment"];
  const trackNetworkContextAmplitudeEvent = useNetworkContextAmplitudeEvent();

  const [applyNetworkChangesNew] = usePostNetworkChangesMutationNew();
  const [applyNetworkChangesLegacy] = usePostNetworkChangesMutationLegacy();

  const applyChanges = useCallback(() => {
    // Check if the client should use legacy scoring (case-insensitive)
    const isLegacyClient = shouldUseLegacyScoring(currentClient);
    
    if (isLegacyClient) {
      // For legacy clients, ONLY call the legacy API
      applyNetworkChangesLegacy({
        networkId,
        specialtyId,
        toAdd,
        toRemove,
      })
        .unwrap()
        .then((result) => {
          console.log("Applied change to legacy API", result);
          
          // Track the event after API call succeeds
          trackNetworkContextAmplitudeEvent("Submit Provider Update", {
            addedProviderCount: toAdd.length,
            removedProviderCount: toRemove.length,
          });
          
          // Clear the stage after successful API call
          clearStage();
          
          return result;
        })
        .catch(handleOpenSnack);
      return; // Return early after handling legacy case - NEVER call new API for legacy clients
    }

    if (useScoreSimulationApi) {
      // Only call the new J2 API when the feature flag is enabled
      applyNetworkChangesNew({
        networkId,
        specialtyId,
        toAdd,
        toRemove,
        user,
      })
        .unwrap()
        .then((result) => {
          console.log("Applied change to J2 API", result);
          
          // Track the event after API call succeeds
          trackNetworkContextAmplitudeEvent("Submit Provider Update", {
            addedProviderCount: toAdd.length,
            removedProviderCount: toRemove.length,
          });
          
          // Clear the stage after successful API call
          clearStage();
          
          return result;
        })
        .catch(handleOpenSnack);
    } else {
      // Call the legacy API and then the new API in the background when the feature flag is disabled
      applyNetworkChangesLegacy({
        networkId,
        specialtyId,
        toAdd,
        toRemove,
      })
        .unwrap()
        .then((legacyResult) => {
          console.log("Applied change to legacy API", legacyResult);
          
          // Track the event immediately after legacy API call succeeds
          trackNetworkContextAmplitudeEvent("Submit Provider Update", {
            addedProviderCount: toAdd.length,
            removedProviderCount: toRemove.length,
          });
          
          // Clear the stage immediately after successful legacy API call
          clearStage();
          
          // Always post to the j2 API after the legacy API succeeds for non-legacy clients
          // But do it in a "fire and forget" manner
          applyNetworkChangesNew({
            networkId,
            specialtyId,
            toAdd,
            toRemove,
            user,
          })
            .unwrap()
            .then((j2Result) => {
              console.log("Applied change to J2 API", j2Result);
            })
            .catch((j2Error) => {
              // Just log the error but don't show it to the user
              console.error("Error applying changes to J2 API:", j2Error);
            });
            
          // Return the legacy result immediately
          return legacyResult;
        })
        .catch(handleOpenSnack);
    }
  }, [
    applyNetworkChangesLegacy,
    applyNetworkChangesNew,
    clearStage,
    currentClient,
    networkId,
    specialtyId,
    toAdd,
    toRemove,
    trackNetworkContextAmplitudeEvent,
    user,
    useScoreSimulationApi,
  ]);

  const hasChanges = toAdd.length > 0 || toRemove.length > 0;
  return (
    <div className="provider-cart">
      {hasChanges ? (
        <>
          <div className="changes">
            {toAdd.length !== 0 && (
              <span
                className="positive-changes"
                onClick={() => handleOpen(0)}
              >{`+${toAdd.length}`}</span>
            )}
            {toAdd.length !== 0 && toRemove.length !== 0 && (
              <span>&nbsp;|&nbsp;</span>
            )}
            {toRemove.length !== 0 && (
              <span
                className="negative-changes"
                onClick={() => handleOpen(1)}
              >{`-${toRemove.length}`}</span>
            )}
            <span className="undo-icon" onClick={clearStage}>
              <RotateLeft color="primary" fontSize="small" />
            </span>
          </div>
          {(toAdd.length !== 0 || toRemove.length !== 0) && (
            <Button
              variant="text"
              onClick={applyChanges}
              size="small"
              id="provider-cart-apply-edits"
            >
              <strong>Apply</strong>
            </Button>
          )}
        </>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography
            variant="body1"
            style={{
              whiteSpace: "pre-wrap",
              alignSelf: "center",
            }}
          >
            <i>No changes</i>
          </Typography>
        </div>
      )}

      {showItems !== false && (
        <StagedChangesModal
          handleClose={handleClose}
          showItems={showItems}
          applyChanges={applyChanges}
        />
      )}

      <Snackbar
        open={openSnack}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{
          top: [49, null, null, null, null],
          right: [0, null, null, null, null],
        }}
      >
        <Alert onClose={handleCloseSnack} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ProviderCart;
